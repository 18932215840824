import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Heading,
  Grid,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';

import { backend_uri } from '../config';

interface UserDevice {
  device_id: number;
  username: string;
  access_token_expiry: string | null;
  last_access: string;
  device_user_agent: string;
}

const UserLog: React.FC = () => {
  const [userDevices, setUserDevices] = useState<UserDevice[]>([]);
  const [cookies] = useCookies(['access_token']);
  const toast = useToast();
  const navigate = useNavigate();
  const accessToken = cookies.access_token;

  useEffect(() => {
    if (!accessToken) {
      toast({
        title: 'Time is up!',
        description: 'Please log in again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      navigate('/');
    } else {
      const fetchUserDevices = async () => {
        try {
          const response = await axios.get<UserDevice[]>(backend_uri + '/user-devices', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setUserDevices(response.data);
        } catch (error) {
          console.error('Error fetching user devices:', error);
          toast({
            title: 'Error fetching user devices',
            description: 'Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };

      fetchUserDevices();
    }
  }, [accessToken, navigate, toast]);

  return (
    <Grid templateColumns="375px 1fr" minHeight="100vh">
      <Box bg="gray.100" padding="4" display="flex" flexDirection="column" alignItems="center">
        <Image src="/logo.png" alt="Logo" width="300px" />
        <Divider marginTop="20px" />
      </Box>
      <Box marginLeft="30px" marginRight="auto" width="90%" overflowX="auto">
        <Heading as="h2" fontSize="4xl" marginTop="10" marginBottom="4" textAlign="left">
          Welcome to <Text as="span" color="green.400">KALIUM-GLOBAL-SERVER</Text>
        </Heading>
        <Divider marginBottom="20px" />
        <Table variant="striped" colorScheme="gray" size="md">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Status</Th>
              <Th>Last Access</Th>
              <Th>Device User Agent</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userDevices.map((device) => (
              <Tr key={device.device_id}>
                <Td>{device.username}</Td>
                <Td>
                  {device.access_token_expiry ? (
                    <Text color="green.400">Active</Text>
                  ) : (
                    <Text color="red.400">Inactive</Text>
                  )}
                </Td>
                <Td>{new Date(device.last_access).toLocaleString()}</Td>
                <Td>{device.device_user_agent}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Grid>
  );
};

export default UserLog;
