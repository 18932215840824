import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Heading,
  Grid,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import { backend_uri } from '../config';

interface User {
  username: string;
  belongs_to_org: string;
}

const RemoveUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [cookies] = useCookies(['access_token']);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const accessToken = cookies.access_token;

  useEffect(() => {
    if (!accessToken) {
      toast({
        title: 'Time is up!',
        description: 'Please log in again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      navigate('/');
    } else {
      const fetchUsers = async () => {
        try {
          const response = await axios.get<User[]>(backend_uri + "/user/all", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setUsers(response.data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };

      fetchUsers();
    }
  }, [accessToken, navigate, toast]);

  const handleDeleteUser = async (username: string) => {
    try {
      await axios.delete(backend_uri + `/delete/users/${username}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUsers(users.filter((user) => user.username !== username));
      toast({
        title: 'User deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      toast({
        title: 'Error deleting user.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const confirmDelete = (username: string) => {
    setSelectedUser(username);
    onOpen();
  };

  return (
    <Grid templateColumns="375px 1fr" minHeight="100vh">
      <Box bg="gray.100" padding="4" display="flex" flexDirection="column" alignItems="center">
        <Image src="/logo.png" alt="Logo" width="300px" />
        <Divider marginTop="20px" />
      </Box>
      <Box marginLeft="30px" marginRight="auto" width="90%" overflowX="auto">
        <Heading as="h2" fontSize="4xl" marginTop="10" marginBottom="4" textAlign="left">
          Welcome to <Text as="span" color="green.400">KALIUM-GLOBAL-SERVER</Text>
        </Heading>
        <Divider marginBottom="20px" />
        <Table variant="striped" colorScheme="gray" size="md">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Organisation</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.username}>
                <Td>{user.username}</Td>
                <Td>{user.belongs_to_org}</Td>
                <Td>
                  <Button colorScheme="red" onClick={() => confirmDelete(user.username)}>
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete User
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete the user "{selectedUser}"? This action cannot be undone.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => {
                  if (selectedUser) handleDeleteUser(selectedUser);
                  onClose();
                }} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Grid>
  );
};

export default RemoveUsers;
