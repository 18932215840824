import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useToast, Grid, Box, Image, Divider, Heading, Text, VStack, Button, Input, Select } from '@chakra-ui/react';
import {backend_uri} from '../config';


interface VPNClientInfo {
    vpn_client_name: string;
    client_vpn_ip: string;
    public_key: string;
    belongs_to_vpn_server: string;
    belongs_to_org: string;
    device_type: string;
    device_user: string;
    device_password: string;
    device_location: string;
    private_key: string;
}

const CreateVPNClient: React.FC = () => {
    const [cookies] = useCookies(['access_token', 'username']);
    const toast = useToast();
    const navigate = useNavigate();
    const [serverInfo, setServerInfo] = useState<any | null>(null);
    const [selectedServer, setSelectedServer] = useState<string>('');
    const [servers, setServers] = useState<string[]>([]);
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

    const initialFormData: VPNClientInfo = {
        vpn_client_name: '',
        client_vpn_ip: '',
        public_key: '',
        belongs_to_vpn_server: '',
        belongs_to_org: '',
        device_type: '',
        device_user: '',
        device_password: '',
        device_location: '',
        private_key: ''
    };
    
    const accessToken = cookies.access_token;
    const [formData, setFormData] = useState<VPNClientInfo>(initialFormData);

    useEffect(() => {
        if (!accessToken) {
            toast({
                title: 'Time is up!',
                description: 'Please log in again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            navigate('/');
            return; 
        }

        const fetchServers = async () => {
            try {
                const response = await axios.get(backend_uri + "/vpn_server/all", {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const serverNames = response.data.map((server: { vpn_server_name: string }) => server.vpn_server_name);
                setServers(serverNames);
                setSelectedServer(serverNames[0] || '');
            } catch (error) {
                console.error("Failed to fetch servers:", error);
                toast({
                    title: 'Error fetching servers',
                    description: 'Please try again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchServers();
    }, [accessToken, navigate, toast]);

    useEffect(() => {
        if (selectedServer) {
            const fetchClientInfo = async () => {
                try {
                    const response = await axios.get(backend_uri + `/vpn_client/new?belongs_to_server=${selectedServer}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setServerInfo(response.data);
                    setFormData({
                        ...initialFormData,
                        public_key: response.data.public_key,
                        private_key: response.data.private_key,
                        client_vpn_ip: response.data.available_client_ip,
                        belongs_to_vpn_server: selectedServer,
                    });
                    setIsFormVisible(true);
                } catch (error) {
                    console.error('Error fetching client information:', error);
                    toast({
                        title: 'Error fetching client information',
                        description: 'Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsFormVisible(false);
                }
            };

            fetchClientInfo();
        }
    }, [selectedServer, accessToken, toast]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post(backend_uri + "/vpn_client/commit", formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log('Client committed:', response.data);
            toast({
                title: 'Client committed successfully!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/results');
        } catch (error) {
            console.error('Error committing client information:', error);
            toast({
                title: 'Error committing client information',
                description: 'Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Grid templateColumns="375px 1fr" minHeight="100vh">
            <Box bg="gray.100" padding="4" display="flex" flexDirection="column" alignItems="center">
                <Image src="/logo.png" alt="Logo" width="300px" />
                <Divider marginTop="20px" />
            </Box>
            <Box marginLeft="30px" marginRight="auto" width="90%">
                <Heading as="h2" fontSize="4xl" marginTop="10" marginBottom="4" textAlign="left">
                    Welcome to <Text as="span" color="green.400">KALIUM-GLOBAL-SERVER</Text>
                </Heading>
                <Divider marginBottom="60px" />
                <VStack as="form" onSubmit={handleSubmit} spacing={4} alignItems="flex-start" width="100%">
                    <Heading as="h3" fontSize="2xl" marginBottom="4">Create a new VPN Client</Heading>
                    <Box width="100%">
                        <Text>Select Server:</Text>
                        <Select
                            value={selectedServer}
                            onChange={(e) => setSelectedServer(e.target.value)}
                            placeholder="Select VPN Server"
                        >
                            {servers.map((server) => (
                                <option key={server} value={server}>{server}</option>
                            ))}
                        </Select>
                    </Box>
                    {isFormVisible && serverInfo && (
                        <VStack spacing={4} width="100%">
                            <Box width="100%">
                                <Text>VPN Client Name:</Text>
                                <Input
                                    type="text"
                                    name="vpn_client_name"
                                    value={formData.vpn_client_name}
                                    onChange={handleChange}
                                    placeholder="Enter VPN Client Name"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Client VPN IP:</Text>
                                <Input
                                    type="text"
                                    name="client_vpn_ip"
                                    value={formData.client_vpn_ip}
                                    onChange={handleChange}
                                    placeholder={formData.client_vpn_ip}
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Public Key:</Text>
                                <Input
                                    type="text"
                                    name="public_key"
                                    value={formData.public_key}
                                    onChange={handleChange}
                                    placeholder={formData.public_key}
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Belongs to VPN Server:</Text>
                                <Input
                                    type="text"
                                    name="belongs_to_vpn_server"
                                    value={formData.belongs_to_vpn_server}
                                    onChange={handleChange}
                                    placeholder="Enter VPN Server Name"
                                    readOnly
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Belongs to Organization:</Text>
                                <Input
                                    type="text"
                                    name="belongs_to_org"
                                    value={formData.belongs_to_org}
                                    onChange={handleChange}
                                    placeholder="Enter Organization Name"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Device Type:</Text>
                                <Input
                                    type="text"
                                    name="device_type"
                                    value={formData.device_type}
                                    onChange={handleChange}
                                    placeholder="Enter Device Type"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Device User:</Text>
                                <Input
                                    type="text"
                                    name="device_user"
                                    value={formData.device_user}
                                    onChange={handleChange}
                                    placeholder="Enter Device User"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Device Password:</Text>
                                <Input
                                    type="password"
                                    name="device_password"
                                    value={formData.device_password}
                                    onChange={handleChange}
                                    placeholder="Enter Device Password"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Device Location:</Text>
                                <Input
                                    type="text"
                                    name="device_location"
                                    value={formData.device_location}
                                    onChange={handleChange}
                                    placeholder="Enter Device Location"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Private Key:</Text>
                                <Input
                                    type="text"
                                    name="private_key"
                                    value={formData.private_key}
                                    onChange={handleChange}
                                    placeholder={formData.private_key}
                                />
                            </Box>
                            <Button type="submit" colorScheme="teal" alignSelf="flex-start">
                                Create VPN Client
                            </Button>
                        </VStack>
                    )}
                </VStack>
            </Box>
        </Grid>
    );
};

export default CreateVPNClient;
