import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useToast, Grid, Box, Image, Divider, Heading, Text, VStack, Button, Input } from '@chakra-ui/react';
import { backend_uri } from '../config';

const SignUpPage: React.FC = () => {
    const [formData, setFormData] = useState({
        username: '',
        belongs_to_org: '',
        password: ''
    });
    const [cookies] = useCookies(['access_token', 'username']);
    const toast = useToast();
    const navigate = useNavigate();
    const accessToken = cookies.access_token;

    useEffect(() => {
        const checkAccessToken = async () => {
            if (!accessToken) {
                toast({
                    title: 'Time is up!',
                    description: 'Please log in again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/');
            }
        };

        checkAccessToken();
    }, [accessToken, navigate, toast]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!accessToken) {
            toast({
                title: 'Time is up!',
                description: 'Please log in again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            navigate('/');
            return;
        }

        try {
            const response = await axios.post(backend_uri+"/user/sign_up", formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log('User signed up:', response.data);
            toast({
                title: 'User signed up successfully!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/');
        } catch (error) {
            console.error('Error signing up:', error);
            toast({
                title: 'Error signing up. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    return (
        <Grid templateColumns="375px 1fr" minHeight="100vh">
            <Box bg="gray.100" padding="4" display="flex" flexDirection="column" alignItems="center">
                <Image src="/logo.png" alt="Logo" width="300px" />
                <Divider marginTop="20px" />
            </Box>
            <Box marginLeft="30px" marginRight="auto" width="90%">
                <Heading as="h2" fontSize="4xl" marginTop="10" marginBottom="4" textAlign="left">
                    Welcome to <Text as="span" color="green.400">KALIUM-GLOBAL-SERVER</Text>
                </Heading>
                <Divider marginBottom="60px" />
                <VStack as="form" onSubmit={handleSubmit} spacing={4} alignItems="flex-start" width="100%">
                    <Heading as="h3" fontSize="2xl" marginBottom="4">Sign Up</Heading>
                    <VStack spacing={4} width="90%">
                        <Box width="100%">
                            <Text>Username:</Text>
                            <Input
                                type="text"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                placeholder="Enter Username"
                            />
                        </Box>
                        <Box width="100%">
                            <Text>Organization:</Text>
                            <Input
                                type="text"
                                name="belongs_to_org"
                                value={formData.belongs_to_org}
                                onChange={handleChange}
                                placeholder="Enter Organization"
                            />
                        </Box>
                        <Box width="100%">
                            <Text>Password:</Text>
                            <Input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter Password"
                            />
                        </Box>
                    </VStack>
                    <Button type="submit" colorScheme="teal" size="sm" width="7%">Sign Up</Button>
                </VStack>
            </Box>
        </Grid>
    );
};

export default SignUpPage;
