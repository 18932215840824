import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useToast, Grid, Box, Image, Divider, Heading, Text, VStack, Button, Input } from '@chakra-ui/react';
import { backend_uri } from '../config';

interface VPNServerInfo {
    vpn_server_name: string;
    public_ip: string;
    public_key: string;
    vpn_port: number;
    subnet_range: string;
    private_key: string;
}

const CreateVPNServer: React.FC = () => {
    const [cookies] = useCookies(['access_token', 'username']);
    const toast = useToast();
    const navigate = useNavigate();
    const [serverInfo, setServerInfo] = useState<any | null>(null);
    const [formData, setFormData] = useState<VPNServerInfo>({
        vpn_server_name: '',
        public_ip: '',
        public_key: '',
        vpn_port: 51820,
        subnet_range: '',
        private_key: ''
    });
    const accessToken = cookies.access_token;

    useEffect(() => {
        if (!accessToken) {
            toast({
                title: 'Time is up!',
                description: 'Please log in again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            navigate('/');
        } else {
            const fetchServerInfo = async () => {
                try {
                    const response = await axios.get(backend_uri + "/vpn_server/new", {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    setServerInfo(response.data);
                    setFormData({
                        vpn_server_name: '',
                        public_ip: '',
                        public_key: response.data.PUBLIC_KEY,
                        vpn_port: response.data.LISTEN_PORT,
                        subnet_range: response.data.IPv4_RANGE,
                        private_key: response.data.PRIVATE_KEY
                    });
                } catch (error) {
                    console.error('Error fetching server information:', error);
                    toast({
                        title: 'Error fetching server information',
                        description: 'Please try again.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            };

            fetchServerInfo();
        }
    }, [accessToken, navigate, toast]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!accessToken) {
            toast({
                title: 'Time is up!',
                description: 'Please log in again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            navigate('/');
            return;
        }

        try {
            const response = await axios.post(backend_uri + "/vpn_server/commit", formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log('Server committed:', response.data);
            toast({
                title: 'Server committed successfully!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/results');
        } catch (error) {
            console.error('Error committing server information:', error);
            toast({
                title: 'Error committing server information',
                description: 'Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Grid templateColumns="375px 1fr" minHeight="100vh">
            <Box bg="gray.100" padding="4" display="flex" flexDirection="column" alignItems="center">
                <Image src="/logo.png" alt="Logo" width="300px" />
                <Divider marginTop="20px" />
            </Box>
            <Box marginLeft="30px" marginRight="auto" width="90%">
                <Heading as="h2" fontSize="4xl" marginTop="10" marginBottom="4" textAlign="left">
                    Welcome to <Text as="span" color="green.400">KALIUM-GLOBAL-SERVER</Text>
                </Heading>
                <Divider marginBottom="60px" />
                {serverInfo && (
                    <VStack as="form" onSubmit={handleSubmit} spacing={4} alignItems="flex-start" width="100%">
                        <Heading as="h3" fontSize="2xl" marginBottom="4">Create a new VPN Server</Heading>
                        <VStack spacing={4} width="90%">
                            <Box width="100%">
                                <Text>VPN Server Name:</Text>
                                <Input
                                    type="text"
                                    name="vpn_server_name"
                                    value={formData.vpn_server_name}
                                    onChange={handleChange}
                                    placeholder="Enter VPN Server Name"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Public IP:</Text>
                                <Input
                                    type="text"
                                    name="public_ip"
                                    value={formData.public_ip}
                                    onChange={handleChange}
                                    placeholder="Enter server's Public IP"
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Public Key:</Text>
                                <Input
                                    type="text"
                                    name="public_key"
                                    value={formData.public_key}
                                    onChange={handleChange}
                                    placeholder={serverInfo.PUBLIC_KEY}
                                />
                            </Box>
                            <Box width="100%">
                                <Text>VPN Port:</Text>
                                <Input
                                    type="number"
                                    name="vpn_port"
                                    value={formData.vpn_port}
                                    onChange={handleChange}
                                    placeholder={String(serverInfo.LISTEN_PORT)}
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Subnet Range:</Text>
                                <Input
                                    type="text"
                                    name="subnet_range"
                                    value={formData.subnet_range}
                                    onChange={handleChange}
                                    placeholder={serverInfo.IPv4_RANGE}
                                />
                            </Box>
                            <Box width="100%">
                                <Text>Private Key:</Text>
                                <Input
                                    type="text"
                                    name="private_key"
                                    value={formData.private_key}
                                    onChange={handleChange}
                                    placeholder={serverInfo.PRIVATE_KEY}
                                />
                            </Box>
                        </VStack>
                        <Button type="submit" colorScheme="teal" size="sm" width="7%">Commit</Button>
                    </VStack>
                )}
            </Box>
        </Grid>
    );
};

export default CreateVPNServer;
