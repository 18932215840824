import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Results from './components/Results';
import CreateVPNServer from './components/CreateVPNServer';
import CreateVPNClient from './components/CreateVPNClient';
import CreateNewUser from './components/CreateNewUser';
import RemoveUsers from './components/RemoveUsers';
import UserLog from './components/UserLog';
import Login from './components/Login'; 

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/results" element={<Results />} />
                <Route path="/create-vpn-server" element={<CreateVPNServer />} />
                <Route path="/create-vpn-client" element={<CreateVPNClient />} />
                <Route path="/create-new-user" element={<CreateNewUser />} />
                <Route path="/remove-user" element={<RemoveUsers />} />
                <Route path="/user-log" element={<UserLog />} />
            </Routes>
        </Router>
    );
};

export default App;
