import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import {
  Box,
  Button,
  Divider,
  Heading,
  Grid,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Text,
} from '@chakra-ui/react';
import '../styles/Login.css';
import {backend_uri} from '../config';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie] = useCookies(['access_token', 'username']);
  const navigate = useNavigate();
  const toast = useToast();
  // const backend_url = config_vars.backend_url;

  const handleLogin = async () => {
    try {
      // console.log(login_uri);
      const response = await axios.post(backend_uri+"/token", new URLSearchParams({
        username,
        password
      }));
      const accessToken = response.data.access_token;
      const expiration = new Date();
      expiration.setTime(expiration.getTime() + (60 * 60 * 1000));

      setCookie('access_token', accessToken, { path: '/', expires: expiration });
      setCookie('username', username, { path: '/', expires: expiration });
      navigate('/results');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 401) {
          toast({
            title: 'Invalid credentials. Please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } else if (error.response.status === 403) {
          toast({
            title: 'Too many devices logged in with this username.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'An unexpected error occurred.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: 'Network error. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      console.error('Login failed:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const accessToken = cookies.access_token;
    if (accessToken) {
      navigate('/results');
    }
  }, [navigate]);

  return (
    <Grid templateColumns="400px 1fr" minHeight="100vh">
      <Box bg="gray.100" padding="4" display="flex" flexDirection="column" alignItems="center">
        <Image src="/logo.png" alt="Logo" width="300px" />
        <Divider marginTop="20px" />
      </Box>
      <Box marginLeft="30px" marginRight="auto" width="90%">
        <Heading as="h2" fontSize="4xl" marginTop="10" marginBottom="4" textAlign="left">
          Welcome to <Text as="span" color="green.400">KALIUM-GLOBAL-SERVER</Text>
        </Heading>
        <Divider marginBottom="60px" />
        <VStack spacing={4} alignItems="flex-start" width="100%">
          <Input
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            size="md"
          />
          <InputGroup size="md">
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button colorScheme="teal" size="md" onClick={handleLogin} alignSelf="flex-start">
            Login
          </Button>
        </VStack>
      </Box>
    </Grid>
  );
};

export default Login;
